import React, { useState } from 'react';
import { MessageBanner, Color } from '@gleerups/syntax4';
import { useAuth } from '@gleerups/auth';
import { isStudentTrial } from '@gleerups/auth-core';

const StudentVersionConfirmAlert = () => {
  const { user } = useAuth();
  const [studentVersionConfirmed, setStudentVersionConfirmed] = useState(
    window.sessionStorage.getItem('student-version-confirmed') === 'true'
  );

  const showConfirmStudentVersionAlert = !studentVersionConfirmed && isStudentTrial(user);

  if (!showConfirmStudentVersionAlert) return null;

  const confirmStudentVersion = () => {
    window.sessionStorage.setItem('student-version-confirmed', 'true');
    setStudentVersionConfirmed(true);
  };

  return (
    <MessageBanner
      verification="Du provar nu elevversionen av Gleerups digitala läromedel. När vi verifierat att
      du arbetar på angiven skola kommer ditt testkonto uppgraderas till lärarversionen med full
      funktionalitet."
      handleDismissBanner={confirmStudentVersion}
      ariaLabel="Stäng"
      backgroundColor={Color.paradisoGreen}
    />
  );
};

export default StudentVersionConfirmAlert;
