import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Breakpoint,
  Dropdown,
  DropdownTriggerButtonPill,
  Spacing,
  DropdownOption,
} from '@gleerups/syntax4';

import { useTypedSelector } from '../../store/rootReducer';
import { setGradesFilter, getGradesFilter } from '../productsReducer';
import {
  getEducationalStages,
  getEducationalStagesLoading,
} from '../../EducationalStages/educationalStagesReducer';
import { RoutePaths } from '../../routePaths';

const StyledProductGradeFilter = styled(Dropdown)`
  margin: 0 0 0 ${Spacing.XS};

  @media (${Breakpoint.smallMax}) {
    width: 100%;
    margin: ${Spacing.M} 0 0 0;
  }
`;

const ProductsGradesFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const educationalStages = useTypedSelector(getEducationalStages);
  const loading = useTypedSelector(getEducationalStagesLoading);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterQuery = searchParams.get('grade');
    if (filterQuery && educationalStages.length > 0 && !loading) {
      const selectedEducationalStage = educationalStages.find(
        (stage) => stage.slug === filterQuery
      );
      dispatch(setGradesFilter(selectedEducationalStage ? selectedEducationalStage.name : ''));
      history.replace(RoutePaths.TRIAL);
    }
  }, [loading]);

  const onGradeSelect = (option: DropdownOption) => {
    dispatch(setGradesFilter(option.value));
  };

  const gradesFilter = useTypedSelector(getGradesFilter);

  const options = [{ value: '', label: 'Alla läromedel' }].concat(
    educationalStages.map((stage) => ({ value: stage.name, label: stage.name }))
  );
  const selectedGradesFilter = gradesFilter || '';

  return (
    <StyledProductGradeFilter
      aria-label="Filtrera läromedel efter årskurs"
      name="Filtrera läromedel efter årskurs"
      triggerButton={DropdownTriggerButtonPill}
      options={options}
      defaultOption={options[0]}
      selectedKey={selectedGradesFilter}
      onSelect={(option) => onGradeSelect(option)}
    />
  );
};

export default ProductsGradesFilter;
