import React from 'react';

import { MessageBanner, Color } from '@gleerups/syntax4';

const TrialTeacherVerifiedAlert = ({
  onClose,
  key,
}: {
  key: string;
  onClose: (key: string) => void;
}) => (
  <MessageBanner
    verification="Grattis! Verifieringen är klar och du har nu tillgång till lärarversionen av Gleerups digitala läromedel med full funktionalitet"
    handleDismissBanner={() => onClose(key)}
    ariaLabel="Stäng"
    backgroundColor={Color.paradisoGreen}
  />
);

export default TrialTeacherVerifiedAlert;
