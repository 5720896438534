import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { useAuth } from '@gleerups/auth';

import { analytics } from '@gleerups/analytics';
import { addAnonymousUserData } from '@gleerups/error-reporter';
import Header from '../Header/Header';
import StudentVersionConfirmAlert from '../TrialUserConfirmAlert/StudentVersionConfirmAlert';
import Alerts from '../Alerts/Alerts';
import MaterialsView from '../MaterialsView/MaterialsView';
import TeacherRoute from './TeacherRoute';
import { RoutePaths } from '../routePaths';
import { Spinner } from '@gleerups/syntax4';

const RootRoute = () => <MaterialsView />;

const Library = () => {
  const { user, redirectToLogin, pending } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    if (user.isAuthenticated) {
      addAnonymousUserData({
        userId: user.userId.toString(),
        email: user.email,
        schoolId: user.activeSchoolId,
      });
      analytics.setUser(user);
    }
  }, [user]);

  const shouldRedirect =
    !pending &&
    !user.isAuthenticated &&
    (pathname === RoutePaths.HOME || pathname === RoutePaths.ACTIVATE_LICENSE);

  // If not authenticated and trying to access "Mina läromedel" (pathname /) or "Skolans läromedel" we redirect user to login
  // This is needed so that the Header is not rendered when a user tries to access route '/' without being logged in. Otherwise, the header would be shown.
  if (shouldRedirect) {
    redirectToLogin();
    return null;
  }

  if (pending) {
    return <Spinner />;
  }

  return (
    <>
      <Header />
      {user.isAuthenticated && <Alerts />}
      {user.isAuthenticated && user.trialState === 'STUDENT_TRIAL' && (
        <StudentVersionConfirmAlert />
      )}
      <Switch>
        <TeacherRoute path={`${RoutePaths.TRIAL}/:productId`} component={RootRoute} />
        <TeacherRoute path={RoutePaths.TRIAL} component={RootRoute} />
        <TeacherRoute path={RoutePaths.ACTIVATE_LICENSE} component={RootRoute} />
        <Route path={RoutePaths.HOME} component={RootRoute} />
      </Switch>
    </>
  );
};

export default Library;
