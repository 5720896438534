import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Breakpoint, Search } from '@gleerups/syntax4';
import styled from 'styled-components';
import { useTypedSelector } from '../../store/rootReducer';
import {
  setSearchFilter,
  getSearchFilter,
  getFilteredProductsWithLicenses,
} from '../productsReducer';

const QUERY_PARAM_PRODUCT_ID = 'productID';

const StyledSearch = styled(Search)`
  max-width: 23.5rem;

  @media (${Breakpoint.smallMax}) {
    max-width: 100%;
  }
`;

const ProductsSearchFilter = ({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const products = useTypedSelector(getFilteredProductsWithLicenses);

  useEffect(() => {
    // apply SearchFilter From Query Param
    /*
      We need to allow LTI students to get a link to an educational material group
      and be able to select the educational material in the group.
      If the link to 'Mina läromedel' contains a query string with 'productID'
      we will look up the title for the educational material and add to the search
      input field to automatically search/filter out the correct educational material.
    */
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get(QUERY_PARAM_PRODUCT_ID);

    if (productId && products) {
      const product = products.find((p) => p.id === productId);

      if (product && product.title) {
        // NOTE: the article titles uses different hyphens.
        const productTitle = product.title.replace(/[‒–—―‐-−]/i, '-');
        dispatch(setSearchFilter(productTitle));
      }
    }
  }, []);

  const searchFilter = useTypedSelector(getSearchFilter);

  return (
    <StyledSearch
      value={searchFilter}
      onChange={(searchTerm) => {
        if (searchTerm !== searchFilter) {
          dispatch(setSearchFilter(searchTerm));
        }
      }}
      aria-label="Sök efter läromedel eller ämne"
      placeholder="Sök efter läromedel eller ämne"
      className={className}
    />
  );
};

export default ProductsSearchFilter;
