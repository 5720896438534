import { Spacing, Breakpoint } from '@gleerups/syntax4';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${Spacing.XXL};

  > *:not(:last-child) {
    margin-right: ${Spacing.M};
  }

  @media (${Breakpoint.smallMax}) {
    flex-direction: column-reverse;

    > button,
    a {
      width: 100%;
      justify-content: center;
    }

    > a:not(:first-child),
    button:not(:first-child) {
      margin-bottom: ${Spacing.M};
    }
  }
`;

export { ButtonWrapper };
