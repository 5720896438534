import React from 'react';
import { OverlayProvider, globalColorsStyles as GlobalColorsStyles } from '@gleerups/syntax4';
import { ErrorBoundary } from '@gleerups/error-reporter';
import { AuthProvider } from '@gleerups/auth';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import Library from './Library/Library';
import { basename } from './constants';
import configureStore from './store/configureStore';

import GlobalBaseStyles from './styles/GlobalBaseStyles';

const App = () => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <GlobalColorsStyles />
    <GlobalBaseStyles />
    <OverlayProvider>
      <ReduxProvider store={configureStore}>
        <ErrorBoundary>
          <AuthProvider redirectUnauthenticated={false} apiBackendBaseUrl="/api">
            <Router basename={basename}>
              <Library />
            </Router>
          </AuthProvider>
        </ErrorBoundary>
      </ReduxProvider>
    </OverlayProvider>
  </StyleSheetManager>
);

// This implements the default behavior from styled-components v5
// See https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

export default App;
