// Display warning for unsupported browsers
import './windowGlobal';

import React from 'react';
import ReactDOM from 'react-dom';
import { analytics } from '@gleerups/analytics';

import App from './App';
import { initErrorReporter } from './initErrorReporter';

initErrorReporter();

analytics.init({
  appName: 'library',
});

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

const IS_MOCK_MODE = !!import.meta.env['VITE_MSW_MOCK_HANDLER'];

// if running in cypress we do want cypress control which mocks we use
// eslint-disable-next-line
// @ts-ignore
const IS_CYPRESS = window.Cypress;

if (IS_MOCK_MODE && !IS_CYPRESS) {
  const deferRender = async () => {
    const { worker } = await import('./mocks/browserSetup');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      serviceWorker: {
        url: '/min-sida/mockServiceWorker.js',
      },
      onUnhandledRequest(request, print) {
        // check if the request is for a font assets
        if (request.url.includes('/fonts')) {
          return;
        }

        // also ignore request loading header
        if (request.url.includes('/header/latest')) {
          return;
        }
        // Ignore requests to fetch static assets.
        const fileEndings = ['\\.jpg', '\\.svg', '\\.png', '\\.tsx', '\\.ts', '\\.js', '\\.css'];
        if (fileEndings.some((ending) => request.url.match(`\\w+${ending}`))) {
          return;
        }
        console.log('unhandled', request.url);
        // Otherwise, print a warning for any unhandled request.
        print.warning();
      },
    });
  };
  deferRender().then(render);
} else {
  render();
}
