import { LicenseType } from '../types';

const licenseExpirationInfo = (expires: string, type: LicenseType) => {
  const date1 = new Date(expires);
  const date2 = new Date();

  const timeDiff = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates
  const diffInDays = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (type === LicenseType.Trial) {
    return { expiresSoon: diffInDays < 7, diff: diffInDays };
  }

  return { expiresSoon: diffInDays < 30, diff: diffInDays };
};

export default licenseExpirationInfo;
