import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GleerupsHeader } from '@gleerups/header';
import { useAuth } from '@gleerups/auth';
import styled from 'styled-components';

import {
  clearProducts,
  loadAvailableAndLicensedProducts,
  loadLicensedProducts,
  loadTrialProducts,
} from '../Products/productsReducer';
import { RoutePaths } from '../routePaths';

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10000;
`;

const Header = () => {
  const { reload, user } = useAuth();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const fetchProducts = async () => {
    dispatch(clearProducts());
    if (pathname.includes(RoutePaths.ACTIVATE_LICENSE)) {
      dispatch(loadAvailableAndLicensedProducts());
    } else if (pathname.includes(RoutePaths.TRIAL)) {
      dispatch(loadTrialProducts(user.isAuthenticated));
    } else {
      dispatch(loadLicensedProducts(false));
    }
  };

  const onSchoolChanged = async () => {
    // Re-fetch all available products when we have changed school
    Promise.all([fetchProducts(), reload()]);
  };

  return (
    <HeaderWrapper id="gleerups-header">
      <GleerupsHeader onSchoolChanged={onSchoolChanged} />
    </HeaderWrapper>
  );
};

export default Header;
