import 'immer';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, TrialLicenseState, LicenseType } from './types';
import LicenseStatus from './licenseStatus';
import { RootState } from '../store/rootReducer';
import api from '../api/api';
import customSerializeError from '../api/customSerializeError';
import { isSerializedApiFetchError } from '../store/serializedErrors';

const COMPLEMENT = 'Komplement';
export const basename = '/min-sida';

export enum ProductErrorType {
  UnknownError = 'unknownError',
  ProductNotFoundError = 'productNotFoundError',
  ProductUnauthorized = 'productUnauthorized',
}

type InitialState = {
  licensedProducts: Product[];
  availableProducts: Product[];
  trialProducts: Product[];
  gradesFilter: string;
  searchFilter: string;
  productIdToActivate: string;
  error: null | ProductErrorType;
  loading: boolean;
  productIdToShare: string;
  loadingForLicensedProducts: boolean;
  loadingForAvailableProducts: boolean;
  loadingForTrialProducts: boolean;
};

const initialState: InitialState = {
  licensedProducts: [],
  availableProducts: [],
  trialProducts: [],
  gradesFilter: '',
  searchFilter: '',
  productIdToActivate: '',
  error: null,
  loading: false,
  productIdToShare: '',
  loadingForLicensedProducts: false,
  loadingForAvailableProducts: false,
  loadingForTrialProducts: false,
};

export const selectLoading = (state: RootState) =>
  state.products.loadingForLicensedProducts ||
  state.products.loadingForAvailableProducts ||
  state.products.loadingForTrialProducts;

export const loadLicensedProducts = createAsyncThunk(
  'products/fetchLicensedProducts',
  async (flushCache: boolean) => api.fetchProducts('licensed', flushCache),
  {
    serializeError: customSerializeError,
  }
);

export const loadAvailableAndLicensedProducts = createAsyncThunk(
  'products/fetchAvailableProducts',
  async () => api.fetchProducts('availableAndLicensed'),
  {
    serializeError: customSerializeError,
  }
);

export const loadTrialProducts = createAsyncThunk(
  'products/fetchTrialProducts',
  async (userIsLoggedIn: boolean) => {
    if (userIsLoggedIn) {
      return api.fetchProducts('trial');
    }
    return api.fetchPublicTrialProducts();
  },
  {
    serializeError: customSerializeError,
  }
);

function errorToErrorType(error: unknown): ProductErrorType {
  if (!isSerializedApiFetchError(error)) {
    return ProductErrorType.UnknownError;
  }

  if (error.status === 404 || error.status === 422 || error.status === 403) {
    return ProductErrorType.ProductNotFoundError;
  }

  if (error.status === 401) {
    return ProductErrorType.ProductUnauthorized;
  }
  // eslint-disable-next-line no-console
  console.error(`Unexpected error from fetch plan on "${error.url}" status: ${error.status}`);
  return ProductErrorType.UnknownError;
}

const productsReducer = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setGradesFilter: (state, action: PayloadAction<string>) => {
      state.gradesFilter = action.payload;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchFilter = action.payload;
    },
    clearFilters: (state) => {
      state.searchFilter = '';
      state.gradesFilter = '';
    },
    setActivateLicenseModalId: (state, action: PayloadAction<string>) => {
      state.productIdToActivate = action.payload;
    },
    closeLibraryModal: (state) => {
      state.productIdToActivate = '';
      state.productIdToShare = '';
    },
    setShareModalProductId: (state, action: PayloadAction<string>) => {
      state.productIdToShare = action.payload;
    },
    hideProduct: (state, action: PayloadAction<string>) => {
      state.licensedProducts = state.licensedProducts.filter(
        (product) => product.id !== action.payload
      );
      state.availableProducts = state.availableProducts.filter(
        (product) => product.id !== action.payload
      );
      state.trialProducts = state.trialProducts.filter((product) => product.id !== action.payload);
    },
    clearProducts: (state) => {
      state.licensedProducts = [];
      state.availableProducts = [];
      state.trialProducts = initialState.trialProducts;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLicensedProducts.pending, (state) => {
        state.loading = true;
        state.loadingForLicensedProducts = true;
      })
      .addCase(loadLicensedProducts.fulfilled, (state, action) => {
        state.licensedProducts = action.payload;
        state.loadingForLicensedProducts = false;
        state.loading = false;
      })
      .addCase(loadLicensedProducts.rejected, (state, action) => {
        state.loadingForLicensedProducts = false;
        state.loading = false;
        state.error = errorToErrorType(action.error);
      })
      .addCase(loadAvailableAndLicensedProducts.pending, (state) => {
        state.loadingForAvailableProducts = true;
        state.loading = true;
      })
      .addCase(loadAvailableAndLicensedProducts.fulfilled, (state, action) => {
        state.availableProducts = action.payload;
        state.loadingForAvailableProducts = false;
        state.loading = false;
      })
      .addCase(loadAvailableAndLicensedProducts.rejected, (state, action) => {
        state.loadingForAvailableProducts = false;
        state.loading = false;
        state.error = errorToErrorType(action.error);
      })
      .addCase(loadTrialProducts.pending, (state) => {
        state.loadingForTrialProducts = true;
        state.loading = true;
      })
      .addCase(loadTrialProducts.fulfilled, (state, action) => {
        state.trialProducts = action.payload;
        state.loadingForTrialProducts = false;
        state.loading = false;
      })
      .addCase(loadTrialProducts.rejected, (state, action) => {
        state.loadingForTrialProducts = false;
        state.loading = false;
        state.error = errorToErrorType(action.error);
      });
  },
});

const filterProducts = (state: RootState, products: Product[]) => {
  const { searchFilter, gradesFilter } = state.products;
  return (
    products
      // filter on grade
      .filter((product) => !gradesFilter.length || product.educationalStage.name === gradesFilter)
      .filter(
        (product) =>
          // if no search filter
          !searchFilter.length ||
          // or matching titles
          product.title
            .toLowerCase()
            // NOTE: the article titles uses different hyphens 🤯
            .replace(/[‒–—―‐-−]/i, '-')
            .indexOf(searchFilter.toLowerCase()) > -1 ||
          // or matching tags
          product.tags.filter(
            (tagInfo) =>
              // filter on tag
              tagInfo.tag.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
              // filter on subjct name
              tagInfo.subjecName.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
          ).length > 0
      )
  );
};

export const getFilteredProductsWithAvailableLicenses = (state: RootState) =>
  filterProducts(
    state,
    state.products.availableProducts.filter((product) => product.type !== COMPLEMENT)
  );

export const hasProductData = (filter: string) => (state: RootState) => {
  switch (filter) {
    case 'licensed':
      return state.products.licensedProducts.length > 0;
    case 'trial':
      return state.products.trialProducts.length > 0;
    case 'available':
      return state.products.availableProducts.length > 0;
    default:
      return false;
  }
};

export const getFilteredComplementProductsWithAvailableLicenses = (state: RootState) =>
  filterProducts(
    state,
    state.products.availableProducts.filter((product) => product.type === COMPLEMENT)
  );

export const getFilteredProductsAvailableForTrial = (state: RootState) =>
  filterProducts(
    state,
    state.products.trialProducts.filter((product) => product.type !== COMPLEMENT)
  );

export const getFilteredProductsWithLicenses = (state: RootState) =>
  filterProducts(
    state,
    state.products.licensedProducts.filter((product) => product.type !== COMPLEMENT)
  );
export const getFilteredComplementProductsWithLicenses = (state: RootState) =>
  filterProducts(
    state,
    state.products.licensedProducts.filter((product) => product.type === COMPLEMENT)
  );

export const getProductTrialLicenseState = (productId: string) => (state: RootState) => {
  const products = state.products.trialProducts
    .concat(state.products.availableProducts, state.products.licensedProducts)
    .filter((p) => p.id === productId);

  // CONX-2064, why this hack ?
  // For exploro we availableForTrial=false for api/products?filter=available&flushCache=false
  // but availableForTrial=true for  api/products?filter=trial&flushCache=false
  const availableForTrial = products.some((p) => p.availableForTrial);
  if (availableForTrial) {
    const { license: currentlyActiveLicense } = products[0];
    if (!currentlyActiveLicense) return TrialLicenseState.Available;
    if (
      currentlyActiveLicense.type === LicenseType.Trial &&
      currentlyActiveLicense.status !== LicenseStatus.EXPIRED
    )
      return TrialLicenseState.Active;
    if (
      currentlyActiveLicense.type === LicenseType.Trial &&
      currentlyActiveLicense.status === LicenseStatus.EXPIRED
    )
      return TrialLicenseState.Expired;
  }

  return TrialLicenseState.NotAvailable;
};

export const getLoading = (state: RootState) => state.products.loading;
export const getError = (state: RootState) => state.products.error;
export const getHasGradesFilter = (state: RootState) => !!state.products.gradesFilter.length;
export const getGradesFilter = (state: RootState) => state.products.gradesFilter;
export const getHasSearchFilter = (state: RootState) => !!state.products.searchFilter.length;
export const getSearchFilter = (state: RootState) => state.products.searchFilter;
export const getProductIdToActivate = (state: RootState) => state.products.productIdToActivate;
export const getProduct = (productId: string) => (state: RootState) =>
  [
    ...state.products.licensedProducts,
    ...state.products.availableProducts,
    ...state.products.trialProducts,
  ].find((product) => product.id === productId);
export const getLicensedProducts = (state: RootState) => state.products.licensedProducts;
export const getAvailableProducts = (state: RootState) => state.products.availableProducts;
export const getTrialProducts = (state: RootState) => state.products.trialProducts;
export const getProductIdToShare = (state: RootState) => state.products.productIdToShare;

const { actions } = productsReducer;
export const {
  setGradesFilter,
  setSearchFilter,
  setActivateLicenseModalId,
  closeLibraryModal,
  hideProduct,
  clearFilters,
  clearProducts,
  setShareModalProductId,
} = actions;

export default productsReducer.reducer;
