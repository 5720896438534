import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/rootReducer';
import { EducationalStage } from '../Products/types';
import api from '../api/api';

type InitialState = {
  all: EducationalStage[];
  loading: boolean;
};

const initialState: InitialState = {
  all: [],
  loading: false,
};

export const loadEducationalStages = createAsyncThunk(
  'educationalStages/fetchEducationalStages',
  async () => api.fetchEducationalStages()
);

const educationalStagesReducer = createSlice({
  name: 'educationalStages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadEducationalStages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadEducationalStages.fulfilled, (state, action) => {
      state.loading = false;
      state.all = action.payload;
    });
  },
});

export const getEducationalStages = (state: RootState) => state.educationalStages.all;
export const getEducationalStagesLoading = (state: RootState) => state.educationalStages.loading;

export default educationalStagesReducer.reducer;
