import { combineReducers } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import productsReducer from '../Products/productsReducer';
import educationalStagesReducer from '../EducationalStages/educationalStagesReducer';

const rootReducer = combineReducers({
  products: productsReducer,
  educationalStages: educationalStagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
