import React from 'react';
import { useAuth } from '@gleerups/auth';
import { isTeacher } from '@gleerups/auth-core';
import { Overline, Spacing, Breakpoint } from '@gleerups/syntax4';
import styled, { css } from 'styled-components';
import ProductCard from '../ProductCard/ProductCard';
import { Product, TabType } from '../types';
import TeacherActionProductCard from '../ProductCard/TeacherActionProductCard';

const Wrapper = styled.div<{ hasHeader?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: ${Spacing.M};
  padding: 0;
  &&:last-child {
    padding-bottom: ${Spacing.XXXXL};
  }
  ${({ hasHeader }) =>
    !hasHeader &&
    css`
      margin-top: ${Spacing.M};
    `}
  @media (${Breakpoint.smallMax}) {
    grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
  }
`;

const Heading = styled(Overline)`
  && {
    margin: ${Spacing.M} 0;
  }
`;

const NoLicenses = styled.p`
  grid-column-start: 1;
  grid-column-end: -1;
  margin: 0;
  padding: 0;
`;

type ProductCardGroupProps = {
  products?: Product[];
  header?: string;
  renderEmptyGroup?: boolean;
  teacherActionCard?: boolean;
  tab: TabType;
};

const ProductCardGroup = ({
  products,
  header,
  renderEmptyGroup,
  teacherActionCard,
  tab,
}: ProductCardGroupProps) => {
  const { user } = useAuth();

  if (!renderEmptyGroup && !products?.length) {
    return null;
  }

  const isTeacherRole = isTeacher(user);
  const CardComponent = teacherActionCard ? TeacherActionProductCard : ProductCard;
  return (
    <>
      {header && <Heading>{header}</Heading>}
      <Wrapper hasHeader={!!header}>
        {!products?.length ? (
          <NoLicenses>
            Det finns inga tillgängliga läromedel att prova gratis för denna årskurs.
          </NoLicenses>
        ) : (
          products?.map((product, index) => (
            <CardComponent
              key={`${product.id}+${index}`}
              isTeacher={isTeacherRole}
              product={product}
              tab={tab}
            />
          ))
        )}
      </Wrapper>
    </>
  );
};

export default ProductCardGroup;
