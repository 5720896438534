import React from 'react';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { Spacing, Spinner } from '@gleerups/syntax4';
import { useTypedSelector } from '../../store/rootReducer';
import {
  getFilteredProductsWithAvailableLicenses,
  getFilteredComplementProductsWithAvailableLicenses,
  getHasSearchFilter,
  selectLoading,
} from '../productsReducer';

import { getEducationalStages } from '../../EducationalStages/educationalStagesReducer';

import ProductCardGroup from '../ProductCardGroup/ProductCardGroup';
import NoSearchHits from '../NoSearchHits';
import { TabType } from '../types';

const NoProductsToAdd = styled.section`
  font-size: 1.125rem;
  margin-top: ${Spacing.XL};
`;

const AddProductLicenseGrid = () => {
  const products = useTypedSelector(getFilteredProductsWithAvailableLicenses);
  const educationalStages = useTypedSelector(getEducationalStages);
  const complementProducts = useTypedSelector(getFilteredComplementProductsWithAvailableLicenses);
  const hasSearchFilter = useTypedSelector(getHasSearchFilter);
  const loading = useTypedSelector(selectLoading);

  const filterOutTrialProducts = products.filter((product) => product.license?.type !== 'trial');

  const groupedByGrade = groupBy(
    filterOutTrialProducts,
    (product) => product.educationalStage && product.educationalStage.name
  );

  if (loading) {
    return <Spinner />;
  }

  const educationalStageNames = educationalStages.map((stage) => stage.name);

  const hasProducts = products.length || complementProducts.length;

  if (!hasProducts && !loading) {
    if (hasSearchFilter) {
      return <NoSearchHits>Tyvärr, inga träffar på din sökning!</NoSearchHits>;
    }
    return (
      <NoProductsToAdd>
        <p>Skolan har inga licenser att aktivera.</p>
      </NoProductsToAdd>
    );
  }

  return (
    <>
      {educationalStageNames.map((stageName) => (
        <ProductCardGroup
          teacherActionCard
          products={groupedByGrade[stageName]}
          header={stageName}
          renderEmptyGroup={false}
          key={stageName}
          tab={TabType.Activate}
        />
      ))}

      <ProductCardGroup
        teacherActionCard
        products={complementProducts}
        header="Komplement"
        renderEmptyGroup={false}
        key="komplement"
        tab={TabType.Activate}
      />
    </>
  );
};

export default AddProductLicenseGrid;
