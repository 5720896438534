import { ApiFetchError } from '@gleerups/api-fetch';
import { SerializedError } from '@reduxjs/toolkit';
import { SerializedApiFetchError, SerializedApiFetchErrorType } from '../store/serializedErrors';

/**
 * This will enhance the error property on the redux action object
 * if we get a ApiFetchError. Should be used together with createAsyncThunk
 *
 * Notice, redux actions must be serializable.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customSerializeError = (error: unknown): SerializedApiFetchError | SerializedError | any => {
  if (error instanceof ApiFetchError) {
    return {
      type: SerializedApiFetchErrorType,
      name: error.name,
      message: error.message,
      stack: error.stack,
      statusOk: error.response.ok,
      status: error.response.status,
      url: error.response.url,
    };
  }

  // we do not control what type of error we receive - hence the 'any' type of this function
  return error;
};

export default customSerializeError;
