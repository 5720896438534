import { init } from '@gleerups/error-reporter';

export function initErrorReporter() {
  const version = import.meta.env['VITE_APP_VERSION'];
  if (!version) {
    console.error(`Unknown version, VITE_APP_VERSION not set`);
  }
  init({
    application: 'library',
    version: version || 'unknown',
  });
}
