import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

// Disabling redux-immutable-state-invariant and serializable-state-invariant-middleware
// since they impact the performance negatively
// https://redux-toolkit.js.org/api/getdefaultmiddleware#development
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...customizedMiddleware],
});

export default store;
