import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tabs, Breakpoint, Spacing, Body1, Link } from '@gleerups/syntax4';
import styled from 'styled-components';

import { useAuth } from '@gleerups/auth';
import { isStudentTrial } from '@gleerups/auth-core';
import { useMedia } from 'react-use';

import LicensedProductsGrid from '../LicensedProductsGrid/LicensedProductsGrid';
import AddProductLicenseGrid from '../AddProductLicenseGrid/AddProductLicenseGrid';
import TrialProductsGrid from '../TrialProductsGrid/TrialProductsGrid';
import ProductsSearchFilter from '../ProductsSearchFilter/ProductsSearchFilter';
import ProductsGradesFilter from '../ProductsGradesFilter/ProductsGradesFilter';
import { setGradesFilter } from '../productsReducer';
import api from '../../api/api';
import { RoutePaths } from '../../routePaths';

const getSelectedKey = (pathname: string) => {
  if (pathname.includes(RoutePaths.ACTIVATE_LICENSE)) {
    return RoutePaths.ACTIVATE_LICENSE;
  }
  if (pathname.includes(RoutePaths.TRIAL)) {
    return RoutePaths.TRIAL;
  }

  return RoutePaths.HOME;
};

const ProductTabActions = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${Spacing.XS} 0 ${Spacing.M} 0;

  @media (${Breakpoint.smallMax}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const StyledLicencedProductsGrid = styled(LicensedProductsGrid)`
  margin-top: ${Spacing.M};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabDescription = styled(Body1)`
  margin: ${Spacing.M} 0;
`;

const ProductsTabList = () => {
  const { user } = useAuth();
  const [isSkolon, setIsSkolon] = useState(false);

  async function getIsSkolonCustomer(customerId: string) {
    setIsSkolon(await api.getIsSkolonCustomer(customerId));
  }

  useEffect(() => {
    if (user.isAuthenticated) {
      getIsSkolonCustomer(user.activeCustomerId.toString());
    }
  }, [user.isAuthenticated]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMedia(`(${Breakpoint.smallMax})`);

  const selectedKey = getSelectedKey(location.pathname);

  if (
    isSkolon &&
    (selectedKey === RoutePaths.ACTIVATE_LICENSE || selectedKey === RoutePaths.TRIAL)
  ) {
    // redirect since skolon user should not be able to enter the URL in browser to those pages
    // see CONX-1921
    history.replace(RoutePaths.HOME);
  }

  const tabs = [
    {
      label: isMobile ? 'Mina' : 'Mina läromedel',
      value: RoutePaths.HOME,
      children: (
        <Wrapper>
          <ProductTabActions>
            <ProductsSearchFilter />
          </ProductTabActions>
          <StyledLicencedProductsGrid />
        </Wrapper>
      ),
      isDisabled: !user.isAuthenticated,
      disabledText: 'När Gleerups har verifierat ditt konto kan du se dina läromedel',
    },
    {
      label: isMobile ? 'Skolans' : 'Skolans läromedel',
      value: RoutePaths.ACTIVATE_LICENSE,
      children: (
        <Wrapper>
          <TabDescription>
            Här ser du alla tillgängliga lärarlicenser på din skola. Aktivera de läromedel du själv
            ska ha tillgång till. <br /> För att se och dela ut licenser till dina elever, gå till{' '}
            <Link href="/lad/?p=0">Administration</Link>.
          </TabDescription>
          <ProductTabActions>
            <ProductsSearchFilter />
          </ProductTabActions>
          <AddProductLicenseGrid />
        </Wrapper>
      ),
      isDisabled: !user.isAuthenticated || isStudentTrial(user) || isSkolon,
      disabledText: isSkolon
        ? 'Skolans licenser hanteras i Skolon'
        : 'När Gleerups har verifierat ditt konto kan du se skolans läromedel',
    },
    {
      label: isMobile ? 'Prova' : 'Prova gratis',
      value: RoutePaths.TRIAL,
      children: (
        <Wrapper>
          <ProductTabActions>
            <ProductsSearchFilter />
            <ProductsGradesFilter />
          </ProductTabActions>
          <TrialProductsGrid />
        </Wrapper>
      ),
      isDisabled: isSkolon,
      disabledText: 'Skolans licenser hanteras i Skolon',
    },
  ];

  return (
    <Tabs
      title="Läromedel"
      tabs={tabs}
      selectedKey={selectedKey}
      onSelect={(tab) => {
        if (user.isAuthenticated) {
          dispatch(setGradesFilter(''));
          history.push(tab.value);
        }
      }}
    />
  );
};

export default ProductsTabList;
