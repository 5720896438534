const n = () => {
  console.info("User not authorized! Redirecting to /min-sida"), window.location.href = "/min-sida";
};
function r(t, e) {
  if (!e)
    return !1;
  for (const i of t)
    if (e.includes(i))
      return !0;
  return !1;
}
function o(t) {
  return t.isAuthenticated && t.roles.includes("teacher");
}
function a(t) {
  return t.isAuthenticated && t.trialState === "NORMAL_USER" && t.roles.includes("student");
}
function c(t) {
  return t.isAuthenticated && t.trialState === "STUDENT_TRIAL";
}
export {
  r as authorizeRoles,
  a as isNormalStudent,
  c as isStudentTrial,
  o as isTeacher,
  n as redirectToLibrary
};
