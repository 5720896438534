import { RadioGroupOption } from '@gleerups/syntax4';
import { LicenseInfo } from './types';
import { TrialLicenseState, License } from '../Products/types';

const defaultOption = (
  licencesToChooseFrom: RadioGroupOption[],
  openedFromTrialPage: boolean,
  hasTrialOption: boolean
): RadioGroupOption | null => {
  if (licencesToChooseFrom && (openedFromTrialPage || !hasTrialOption)) {
    return licencesToChooseFrom[0];
  }
  if (hasTrialOption && licencesToChooseFrom.length > 1) {
    return licencesToChooseFrom[1];
  }
  return null;
};

const licenseOptions = (
  availableLicenses: LicenseInfo[] | null,
  trialLicenseState?: TrialLicenseState,
  activeLicense?: License
): RadioGroupOption[] => {
  const options: RadioGroupOption[] = [];

  switch (trialLicenseState) {
    case TrialLicenseState.Available:
      options.push({ label: 'Testlicens', badgeText: 'Prova gratis i 30 dagar', value: 'trial' });
      break;
    case TrialLicenseState.Active:
      options.push({
        label: 'Testlicens',
        sublabel: activeLicense
          ? `Aktiv till ${formatDate(activeLicense?.expires.substring(0, 10))}`
          : '',
        value: 'trial',
        isDisabled: true,
      });
      break;
    case TrialLicenseState.Expired:
      options.push({ label: 'Testlicens', sublabel: 'Utgången', value: 'trial', isDisabled: true });
      break;
    default:
  }
  if (availableLicenses) {
    availableLicenses.forEach((license) => {
      const { surplus, starts, ends, id } = license;

      options.push({
        label: `Lärarlicens (${surplus} st. tillgängliga)`,
        sublabel: `${formatDate(starts)} till ${formatDate(ends)}`,
        value: id,
      });
    });
  }

  return options;
};

const formatDate = (datetime: string) =>
  new Date(datetime.replace(/-/g, '/')).toLocaleDateString('sv-SE');

export { defaultOption, formatDate, licenseOptions };
