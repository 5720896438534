import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spacing, Spinner } from '@gleerups/syntax4';
import { Link } from 'react-router-dom';
import { useAuth } from '@gleerups/auth';
import { isTeacher } from '@gleerups/auth-core';
import { addBreadcrumb } from '@gleerups/error-reporter';
import api from '../../api/api';
import { useTypedSelector } from '../../store/rootReducer';
import {
  getFilteredProductsWithLicenses,
  getFilteredComplementProductsWithLicenses,
  getHasSearchFilter,
  getLoading,
} from '../productsReducer';

import { TabType } from '../types';

import NoLicensedResultsView from './NoLicensedResultsView';
import ProductCardGroup from '../ProductCardGroup/ProductCardGroup';
import NoSearchHits from '../NoSearchHits';
import { RoutePaths } from '../../routePaths';

const HowToActivateInfo = styled.p`
  margin-top: ${Spacing.XL};
  text-align: center;
`;

const LicensedProductsGrid = () => {
  const products = useTypedSelector(getFilteredProductsWithLicenses);
  const complementProducts = useTypedSelector(getFilteredComplementProductsWithLicenses);
  const hasSearchFilter = useTypedSelector(getHasSearchFilter);
  const loading = useTypedSelector(getLoading);

  const hasProducts = products.length !== 0 || complementProducts.length !== 0;
  const { user } = useAuth();
  const [isSkolon, setIsSkolon] = useState(false);

  async function getIsSkolonCustomer(customerId: string) {
    const isSkolon = await api.getIsSkolonCustomer(customerId);
    if (isSkolon) {
      addBreadcrumb({ category: 'license_check', message: 'skolon customer' });
    }

    setIsSkolon(isSkolon);
  }

  useEffect(() => {
    if (user.isAuthenticated) {
      getIsSkolonCustomer(user.activeCustomerId.toString());
    }
  }, [user.isAuthenticated]);
  const isTeacherRole = isTeacher(user);

  if (loading) {
    return <Spinner />;
  }

  if (!hasSearchFilter && !hasProducts && !loading) {
    return <NoLicensedResultsView isSkolon={isSkolon} />;
  }

  if (hasSearchFilter && !hasProducts) {
    return <NoSearchHits>Tyvärr, inga träffar på din sökning!</NoSearchHits>;
  }

  return (
    <>
      {hasProducts && (
        <>
          <ProductCardGroup products={products} tab={TabType.MyEducationalMaterial} />
          <ProductCardGroup
            products={complementProducts}
            header="Komplement"
            tab={TabType.MyEducationalMaterial}
          />
        </>
      )}

      {isTeacherRole && !hasSearchFilter && hasProducts && !isSkolon && (
        <HowToActivateInfo>
          Under fliken <Link to={RoutePaths.ACTIVATE_LICENSE}>Skolans läromedel</Link> kan du hitta
          fler tillgängliga läromedel att aktivera.
        </HowToActivateInfo>
      )}
    </>
  );
};

export default LicensedProductsGrid;
