import React from 'react';
import { Heading3, Spacing, ButtonBig } from '@gleerups/syntax4';
import styled from 'styled-components';
import { FocusScope } from 'react-aria';
import { formatDate } from '../helpers';
import { ButtonWrapper } from '../sharedComponents';
import { ProductInfo } from '../types';
import { RoutePaths } from '../../routePaths';

type LicenseActivatedContentProps = {
  productInfo: ProductInfo;
  endDate: string | null;
  activatedIsTrial: boolean | null;
  onCloseButtonClick: () => void;
};

const Info = styled.div`
  margin-top: ${Spacing.M};
`;

const LicenseInfo = (productTitle: string, endDate: string) => (
  <>
    <Heading3>Lärarlicens aktiverad</Heading3>
    <Info>
      <p>
        Du har nu tillgång till läromedlet <b>{productTitle}</b> t.o.m. {formatDate(endDate)}
      </p>
      <p>
        Ska dina elever också ha detta läromedel? Du tilldelar dem elevlicenser via{' '}
        <a href="/lad" tabIndex={0}>
          Administration
        </a>
      </p>
      <p>
        Har du några frågor är du välkommen att kontakta{' '}
        <a href="/support" tabIndex={0}>
          Gleerups support
        </a>
        . Lycka till!
      </p>
    </Info>
  </>
);

const TrialLicenseInfo = (productTitle: string) => (
  <>
    <Heading3>Testlicens aktiverad</Heading3>
    <Info>
      <p>
        Du har nu tillgång till läromedlet <b>{productTitle}</b> gratis i 30 dagar
      </p>
      <p>
        Har du några frågor är du välkommen att kontakta{' '}
        <a href="/support" tabIndex={0}>
          Gleerups support
        </a>
        . Lycka till!
      </p>
    </Info>
  </>
);

const LicenseActivatedContent = ({
  productInfo,
  activatedIsTrial,
  endDate,
  onCloseButtonClick,
}: LicenseActivatedContentProps) => {
  const { title: productTitle, url: educationalMaterialUrl } = productInfo;
  const url =
    educationalMaterialUrl && educationalMaterialUrl.includes('.php')
      ? educationalMaterialUrl
      : `${educationalMaterialUrl}/start`;

  return (
    <FocusScope autoFocus>
      {!activatedIsTrial && endDate
        ? LicenseInfo(productTitle, endDate)
        : TrialLicenseInfo(productTitle)}

      <ButtonWrapper>
        {window.location.href.endsWith(RoutePaths.ACTIVATE_LICENSE) ? (
          <ButtonBig color="outlinedBlue" href={`${window.location.origin}${RoutePaths.LAD}`}>
            Administrera elever
          </ButtonBig>
        ) : (
          <ButtonBig color="outlinedBlue" onClick={onCloseButtonClick}>
            Stäng
          </ButtonBig>
        )}
        <ButtonBig color="blue" href={`${window.location.origin}${url}`}>
          Gå till läromedlet
        </ButtonBig>
      </ButtonWrapper>
    </FocusScope>
  );
};

export default LicenseActivatedContent;
