import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '@gleerups/auth';
import { isNormalStudent } from '@gleerups/auth-core';
import { RoutePaths } from '../routePaths';

type TeacherRouteProps = {
  exact?: boolean;
  path: string;
  component: React.ComponentType<unknown>;
};

const TeacherRoute = ({ component: Component, ...rest }: TeacherRouteProps) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        if (isNormalStudent(user) && user.isAuthenticated) {
          return <Redirect to={RoutePaths.HOME} />;
        }

        return <Component />;
      }}
    />
  );
};

export default TeacherRoute;
