import { flatMap, uniqWith, isEqual } from 'lodash';

// Material products
export const createTagsForMaterial = (material) =>
  material.subjects.map((subject) => ({
    tag: `${subject.shortName} ${material.grade.year}`,
    subjecName: subject.name,
  }));

export const createTagsForMaterialGroups = (educationalMaterials) => {
  const allMaterialTags = flatMap(educationalMaterials, createTagsForMaterial);
  const allUniqueMaterialTags = uniqWith(allMaterialTags, isEqual);
  return allUniqueMaterialTags;
};

export default {
  createTagsForMaterial,
  createTagsForMaterialGroups,
};
