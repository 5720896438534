import { Badge, Breakpoint, Color, Spacing } from '@gleerups/syntax4';
import React from 'react';
import { useMedia } from 'react-use';
import styled, { css } from 'styled-components';
import { LicenseType, TagInfo } from '../types';
import { Product } from '@gleerups/api-fetch';
import licenseExpirationInfo from './licenseExpirationInfo';
import { RoutePaths } from '../../routePaths';
import LicenseStatus from '../licenseStatus';

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;

  @media (${Breakpoint.smallMax}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

const StyledGY25 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${Spacing.XS};
  margin-left: ${Spacing.XS};
  border-radius: 0 0.25rem 0.25rem 0;

  @media (${Breakpoint.smallMax}) {
    display: inline;
    flex-direction: row;
    margin-top: ${Spacing.XXS};
    margin-left: ${Spacing.XXS};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: ${Spacing.XS} ${Spacing.XS} 0 0;

  @media (${Breakpoint.smallMax}) {
    margin: 0 4px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-column-gap: ${Spacing.XXS};
    grid-auto-flow: column;

    > div:last-child {
      grid-row: 2;
    }
  }
`;

const CardTag = styled(Badge)<{ backgroundColor: string }>`
  margin-bottom: ${Spacing.XXS};

  && {
    padding-left: ${Spacing.XXS};
    padding-right: ${Spacing.XXS};
    background-color: ${(props) => props.backgroundColor};

    > h6 {
      ${({ backgroundColor }) =>
        backgroundColor === Color.supernovaYellow ||
        backgroundColor === Color.yellowSea ||
        backgroundColor === Color.lilyWhite ||
        backgroundColor === Color.white
          ? css`
              color: ${Color.black};
            `
          : css`
              color: ${Color.white};
            `}
    }
  }
`;

const CardTagGY25 = styled(CardTag)<{ isGy25: boolean; isKomvux25: boolean }>`
  border-radius: 0.25rem 0 0.25rem 0;
  @media (${Breakpoint.smallMax}) {
    border-radius: ${({ isGy25, isKomvux25 }) =>
      isGy25 && !isKomvux25 ? '0.25rem 0 0.25rem 0' : '0.25rem 0 0 0'};
  }
`;

const CardTagKomvux25 = styled(CardTag)<{ isGy25: boolean; isKomvux25: boolean }>`
  border-radius: 0.25rem 0 0.25rem 0;
  @media (${Breakpoint.smallMax}) {
    border-radius: ${({ isGy25, isKomvux25 }) =>
      !isGy25 && isKomvux25 ? '0.25rem 0 0.25rem 0' : '0 0 0.25rem 0'};
    margin-left: ${({ isGy25, isKomvux25 }) => (isGy25 && isKomvux25 ? `${Spacing.XXS}` : 0)};
  }
`;

type TagWrapperProps = {
  expired?: boolean;
  expiresSoon: boolean;
  tagText: string;
  isTeacher?: boolean;
  diff?: number;
};

const TagWrapper = ({ expired, expiresSoon, tagText, isTeacher, diff }: TagWrapperProps) => {
  if (expired) {
    return <CardTag backgroundColor={Color.lavaRed}>{`${tagText} utgången`}</CardTag>;
  }
  if (expiresSoon && isTeacher) {
    return (
      <CardTag backgroundColor={Color.yellowSea}>{`${tagText} går ut om ${diff} ${
        diff === 1 ? 'dag' : 'dagar'
      }`}</CardTag>
    );
  }

  if (tagText === 'Testlicens') {
    return <CardTag backgroundColor={Color.paradisoGreen}>{tagText}</CardTag>;
  }

  return tagText !== 'Licens' ? (
    <CardTag backgroundColor={Color.midnightBlue}>{tagText}</CardTag>
  ) : null;
};

interface ProductWithTags extends Product {
  tags: TagInfo[];
}

type CardTagListProps = {
  isComplement?: boolean;
  product: ProductWithTags;
  expired?: boolean;
  showCategoryTags?: boolean;
  isTeacher?: boolean;
};

const CardTagList = ({
  isComplement,
  product,
  expired,
  showCategoryTags,
  isTeacher,
}: CardTagListProps) => {
  const { type, expires } = product.license || {};
  const { expiresSoon, diff } = licenseExpirationInfo(expires || '', type as LicenseType);
  const largeScreen = useMedia(`(${Breakpoint.mediumMin})`);

  const getComplementText = () => {
    if (type === LicenseType.Trial && product.availableLicenses && isTeacher) {
      return 'Testlicens';
    }

    if (expired || expiresSoon) {
      return 'Komplementlicens';
    }

    return 'Komplement';
  };

  const { isGy25, isKomvux25 } = product;
  const isProductActivated =
    product.license?.status === LicenseStatus.ACTIVE ||
    product.license?.status === LicenseStatus.EXPIRES_SOON;
  const isActivateLicense = window.location.href.includes(RoutePaths.ACTIVATE_LICENSE);

  return (
    <CardWrapper>
      <StyledGY25>
        {isGy25 && (
          <CardTagGY25
            backgroundColor={Color.frenchBlue}
            isGy25={isGy25}
            isKomvux25={isKomvux25 ?? false}
          >
            Gy25
          </CardTagGY25>
        )}
        {isKomvux25 && (
          <CardTagKomvux25
            backgroundColor={Color.frenchBlue}
            isGy25={isGy25 ?? false}
            isKomvux25={isKomvux25}
          >
            Komvux25
          </CardTagKomvux25>
        )}
      </StyledGY25>

      <Wrapper>
        {showCategoryTags &&
          !isProductActivated &&
          product.tags.map(({ tag }) => (
            <CardTag key={tag} backgroundColor={Color.white}>
              {tag}
            </CardTag>
          ))}
        {largeScreen && !isActivateLicense && (
          <>
            {isComplement && (
              <TagWrapper
                tagText={getComplementText()}
                expired={expired}
                expiresSoon={expiresSoon}
                isTeacher={isTeacher}
                diff={diff}
              />
            )}
            {!isComplement && !showCategoryTags && type === LicenseType.Preview && (
              <CardTag backgroundColor={Color.pigletPink}>Förhandstitt</CardTag>
            )}
            {!isComplement && !showCategoryTags && type === LicenseType.Trial && (
              <TagWrapper
                tagText="Testlicens"
                expired={expired}
                expiresSoon={expiresSoon}
                isTeacher={isTeacher}
                diff={diff}
              />
            )}
            {!isComplement && type === LicenseType.Normal && (
              <TagWrapper
                tagText="Licens"
                expired={expired}
                expiresSoon={expiresSoon}
                isTeacher={isTeacher}
                diff={diff}
              />
            )}
          </>
        )}
        {largeScreen && isActivateLicense && isProductActivated && (
          <CardTag backgroundColor={Color.fokusGrassGreen}>Licens aktiverad</CardTag>
        )}
        {!showCategoryTags &&
          !isActivateLicense &&
          (type === LicenseType.Trial || expired || expiresSoon) &&
          product.availableLicenses &&
          isTeacher && (
            <CardTag backgroundColor={Color.slatePurple}>
              {(expired || expiresSoon) && largeScreen
                ? 'Ny lärarlicens tillgänglig'
                : 'Lärarlicens tillgänglig'}
            </CardTag>
          )}
      </Wrapper>
    </CardWrapper>
  );
};

export default CardTagList;
