export interface SerializedApiFetchError {
  type: 'ApiFetchError';
  name: string;
  message: string;
  stack?: string;
  statusOk: boolean;
  status: number;
  url: string;
}

export const SerializedApiFetchErrorType = 'ApiFetchError';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSerializedApiFetchError(error: any): error is SerializedApiFetchError {
  return error && error.type === SerializedApiFetchErrorType;
}
