import React from 'react';
import { groupBy } from 'lodash';
import { IllustratedMessage, Spinner } from '@gleerups/syntax4';
import { useTypedSelector } from '../../store/rootReducer';
import {
  getFilteredProductsAvailableForTrial,
  getHasSearchFilter,
  getHasGradesFilter,
  getGradesFilter,
  getLoading,
} from '../productsReducer';
import { getEducationalStages } from '../../EducationalStages/educationalStagesReducer';

import { Product, TabType } from '../types';
import NoSearchHits from '../NoSearchHits';
import ProductCardGroup from '../ProductCardGroup/ProductCardGroup';

const TrialProductsGrid = () => {
  const products = useTypedSelector(getFilteredProductsAvailableForTrial);
  const educationalStages = useTypedSelector(getEducationalStages);
  const hasSearchFilter = useTypedSelector(getHasSearchFilter);
  const hasGradesFilter = useTypedSelector(getHasGradesFilter);
  const gradesFilter = useTypedSelector(getGradesFilter);
  const loading = useTypedSelector(getLoading);
  const groupedByGrade = groupBy<Product>(
    products,
    (product) => product.educationalStage && product.educationalStage.name
  );

  const educationalStageNames = educationalStages.map((stage) => stage.name);

  if (loading) {
    return <Spinner />;
  }

  if (!products.length && !hasSearchFilter && !hasGradesFilter && !loading) {
    return (
      <IllustratedMessage
        illustrationName="empty"
        heading="Inga läromedel"
        body="Det finns inga läromedel att prova."
      />
    );
  }

  if (!products.length && hasSearchFilter) {
    return <NoSearchHits>Tyvärr, inga träffar på din sökning!</NoSearchHits>;
  }

  return (
    <>
      {!products.length && gradesFilter ? ( // Show empty group for selected grade
        <ProductCardGroup
          teacherActionCard
          header={gradesFilter}
          renderEmptyGroup
          tab={TabType.Trial}
        />
      ) : (
        educationalStageNames.map((stageName) => {
          // if no grades filter show all ProductGroups and only render emptyGroup if there is a search filter
          if (!hasGradesFilter) {
            return (
              <ProductCardGroup
                teacherActionCard
                products={groupedByGrade[stageName]}
                header={stageName}
                renderEmptyGroup={!hasSearchFilter}
                key={stageName}
                tab={TabType.Trial}
              />
            );
          }

          // if grades filter only show grade but do renderEmptyGroup
          if (groupedByGrade[stageName]) {
            return (
              <ProductCardGroup
                teacherActionCard
                products={groupedByGrade[stageName]}
                header={stageName}
                renderEmptyGroup
                key={stageName}
                tab={TabType.Trial}
              />
            );
          }

          return null;
        })
      )}
    </>
  );
};

export default TrialProductsGrid;
