import React, { useEffect, useState } from 'react';
import AlertTypes from './alertTypes';
import api from '../api/api';

import TrialTeacherVerifiedAlert from './TrialTeacherVerifiedAlert';

type Alert = { id: string; type: string };

const FetchState = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
};

const Alerts = () => {
  const [current, setCurrent] = useState<string>(FetchState.LOADING);
  const [alerts, setAlerts] = useState<Alert[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const alertResult = await api.fetchAlerts();
        setCurrent(FetchState.SUCCESS);
        setAlerts(alertResult);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const markAsRead = async (alertId: string) => {
    await api.markAlertAsRead(alertId);
    // Remove alert from collection
    setAlerts(alerts.filter((alert) => alert.id !== alertId));
  };

  if (current === FetchState.SUCCESS) {
    // render alerts
    return (
      <section className="alerts">
        {alerts.map((alert) => {
          if (alert.type === AlertTypes.TRIAL_TEACHER_VERIFIED) {
            return (
              <TrialTeacherVerifiedAlert key={alert.id} onClose={() => markAsRead(alert.id)} />
            );
          }

          console.error('Unknown alert type', alert.type);

          return null;
        })}
      </section>
    );
  }

  return null;
};

export default Alerts;
