import { createTagsForMaterial, createTagsForMaterialGroups } from '../Products/helpers/createTags';

import { INTERACTIVE_BOOK_GROUP } from '../constants';

// Material products
const formatMaterialProduct = (material) => {
  const { url, ...rest } = material;

  const tags = createTagsForMaterial(material);

  const baseUrl = `${window.location.origin}${url}`;
  const materialLink = url && url.includes('.php') ? baseUrl : `${baseUrl}/start`;

  return {
    ...rest,
    url,
    tags,
    materialLink,
  };
};

export const materialLinkToSlug = (materialLink) => {
  const m = materialLink.match(/\/([^/]+)\/start$/);
  return m ? m[1] : '';
};

// Material group products
const formatMaterialGroupProduct = (material) => {
  const { educationalMaterials, ...rest } = material;

  const tags = createTagsForMaterialGroups(educationalMaterials);
  const formattedEducationalMaterials = educationalMaterials.map(({ id, title, label, slug }) => ({
    id,
    title,
    label,
    materialLink: `${window.location.origin}/laromedel/${slug}/start`,
  }));

  return {
    ...rest,
    tags,
    educationalMaterials: formattedEducationalMaterials,
  };
};

const formatMaterials = (materials) =>
  materials.reduce((result, material) => {
    const { type } = material;

    const formattedMaterial =
      type === INTERACTIVE_BOOK_GROUP
        ? formatMaterialGroupProduct(material)
        : formatMaterialProduct(material);

    return result.concat(formattedMaterial);
  }, []);

export default formatMaterials;
