import React from 'react';
import { ModalSmall, ButtonBig, Spacing } from '@gleerups/syntax4';
import styled from 'styled-components';
import { ButtonWrapper } from '../sharedComponents';

type ModalProps = {
  onClose: () => void;
  numberOfAvailableLicenses: number;
  activateLicense: () => void;
  productTitle: string;
};

const StyledModalSmall = styled(ModalSmall)`
  > div {
    margin-top: ${Spacing.XXXXXXL};
  }
`;
// due to problems when clicking outside; closed parent modal also. We decided to make this modal isDismissable=false
// maybe try fixing this in the future to be able to close just by clicking outside modal
const ConfirmationDialog = ({
  onClose,
  activateLicense,
  numberOfAvailableLicenses,
  productTitle,
}: ModalProps) => (
  <StyledModalSmall onClose={onClose} isDismissable={false} heading="Aktivera licens">
    Du kommer nu aktivera <b>1 av de {numberOfAvailableLicenses} lärarlicenser</b> som finns
    tillgängliga på skolan för läromedlet <b>{productTitle}</b>. När du aktiverat licensen kan den
    inte användas av andra än dig. Är du säker på att att du vill fortsätta?
    <ButtonWrapper>
      <ButtonBig color="outlinedBlue" onClick={onClose}>
        Nej
      </ButtonBig>
      <ButtonBig color="blue" onClick={activateLicense}>
        Ja
      </ButtonBig>
    </ButtonWrapper>
  </StyledModalSmall>
);

export default ConfirmationDialog;
